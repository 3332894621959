import React, { useState, useEffect } from 'react';
import Head from './componets/Layout/Head';
import Footer from './componets/Layout/Footer';
import _ from "lodash";
import "bootstrap/dist/css/bootstrap.min.css";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import AppRoutes from './routes/AppRoutes';
import {
  BrowserRouter as Router,
  Route,
  Routes
} from "react-router-dom";
import Home from './componets/Home/Home';
function App(props) {




  return (
    <>
      <Router>       
        <Home />       
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </Router>
    </>

  );
}


export default App;
