import axios from "axios";
import { toast } from 'react-toastify';

const instance = axios.create({
    baseURL: ''
});
instance.defaults.withCredentials = true;
instance.interceptors.request.use(function (config) {
    return config;
}, function (error) {
    return Promise.reject(error);
});


instance.interceptors.response.use(function (response) {
    return response;
}, function (err) {

    const status = err.response.data.status;
    
    // we can handle global errors here
    
    switch (status) {
       
        case 401: {          
            toast.error('You are not logged in.');  
            localStorage.removeItem('auth');
            window.location.href = '/login';
        }

        case 403: {           
            toast.error("You don't have sufficient privileges.");
            localStorage.removeItem('auth');
            window.location.href = '/login';
            return 401;
        }

        case 400: {
            window.location.href = '/login';
            return 401;
        }

        case 404: {
             window.location.href = '/login';
            return 401;
        }

        case 409: {
            window.location.href = '/login';
            return 401;
        }

        case 422: {
            window.location.href = '/login';
            return true;
        }

        default: {
            window.location.href = '/login';
            return true;
        }
    }

    return Promise.reject(err)
});

export default instance;