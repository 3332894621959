// src/ParticlesComponent.js
import React, { useEffect } from 'react';
import particlesJS from 'particles.js';


// Cấu hình particles.js
const particlesConfig = {
  particles: { 
    number: {
      value: 80, // Số lượng particles
    },
    size: {
      value: 4,
    },
    shape: {
      type: "triangle",
    },
    move: {
      direction: "none",
          enable: true,
          outModes: {
            default: "bounce",
          },
          random: true,
          speed: 3,
          straight: false,
    },
    line_linked: {
      enable: true,
      distance: 200,
      color:"#0ab8cf"
    },
    color:{
      value:'#e6e6e6'
    }
  },
  interactivity: {
    events: {
      onhover: {
        enable: true,
        mode: 'grab', 
      },
    },
    modes: {
      grab: {
            distance: 350           
          },
    }
  },
  retina_detect: true, 
};

const ParticlesComponent = () => {
  useEffect(() => {
    if (particlesJS) {
      window.particlesJS('particles-js', particlesConfig);     
    } else {      
    }
  }, []);

  return (
    <div>
    
      <div
        id="particles-js"
        style={{
          position: 'fixed',
          width: '100%',
          height: '100%',
          top: 0,
          left: 0,
          zIndex: -1, 
        }}
      />
    </div>
  );
};

export default ParticlesComponent;
