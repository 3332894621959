import React from 'react';
import About from './About';
import Presale from './Presale';
import ParticlesComponent from "./ParticlesComponent";
import './home.css';
function Home(props) {
  return (
    <>
   
      <div className='page-home animated-bg'>      
        <ParticlesComponent />
        <Presale />       
      </div>

    </>
  );
}

export default Home;